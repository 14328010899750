import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = (data, location) => {
  const url = typeof window !== "undefined" ? window.location.href : ""
  const path = url.split("/")[3]
  const id = url.split("/")[4]

  useEffect(() => {
    if (path === "item") {
      navigate("/items/" + id)
    } else if (path === "list") {
      navigate("/lists/" + id)
    }
  }, [id, path])

  if (path === "item" || path === "list") {
    return <></>
  } else {
    return (
      <Layout>
        <Seo title="404: Not found" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
  }
}

export default NotFoundPage
